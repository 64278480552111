import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet beix grisenc d’olor a farina que presenta un capell de fins a 6 cm de diàmetre, umbilicat i que amb l’edat adquireix forma de copa. A la zona umbilicada és un poc hirsut i presenta fibril·les longitudinals cap als marges. Làmines de color més clar que el capell, decurrents i ondulades a prop dels marges. El peu és buit i esclafat i es tenyeix de rosa, igual que les làmines per la caiguda de les espores. Aquestes són quasi pentagonals, de 9-11 x 5,5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      